import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { deleteNote } from './notesSlice';
import { deleteUnisonProject } from './unisonProjectsSlice';
import {
  DecoratedUnisonProjectNote,
  UndecoratedNoteUnisonProject,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type UnisonProjectNotesState = {
  byID: Record<string, UndecoratedNoteUnisonProject>;
  byUnisonProjectID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUnisonProjectID: {},
} satisfies UnisonProjectNotesState as UnisonProjectNotesState;

export const unisonProjectNotesSlice = createSlice({
  name: 'unisonProjectNotes',
  initialState,
  reducers: {
    setUnisonProjectNotes: (
      state,
      action: PayloadAction<DecoratedUnisonProjectNote[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addUnisonProjectNote: (
      state,
      action: PayloadAction<DecoratedUnisonProjectNote>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateUnisonProjectNote: (
      state,
      action: PayloadAction<DecoratedUnisonProjectNote>
    ) => {
      return getNextState(state, [action.payload]);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byUnisonProjectID: {} };
      })
      .addCase(deleteUnisonProject, (state, action) => {
        let byID = { ...state.byID };
        let byUnisonProjectID = { ...state.byUnisonProjectID };
        Object.keys(byID).forEach((key) => {
          if (byID[key].unisonProjectID === action.payload.projectID) {
            delete byID[key];
          }
        });
        delete byUnisonProjectID[action.payload.projectID];
        return { byID, byUnisonProjectID };
      })
      .addCase(deleteNote, (state, action) => {
        let byID = { ...state.byID };
        let byUnisonProjectID = { ...state.byUnisonProjectID };
        Object.keys(byID).forEach((key) => {
          if (byID[key].noteID === action.payload.noteID) {
            delete byID[key];
          }
        });
        Object.keys(byUnisonProjectID).forEach((projectID) => {
          byUnisonProjectID[projectID] = byUnisonProjectID[projectID].filter(
            (key) => {
              return byID[key] !== undefined;
            }
          );
        });
        return { byID, byUnisonProjectID };
      })
      .addDefaultCase(() => {});
  },
});

function formatUnisonProjectNote({
  note,
  ...unisonProjectNote
}: DecoratedUnisonProjectNote) {
  return unisonProjectNote;
}

function getNextState(
  state: UnisonProjectNotesState,
  unisonProjectNotes: DecoratedUnisonProjectNote[]
) {
  let byID = { ...state.byID };
  let byUnisonProjectID = { ...state.byUnisonProjectID };
  unisonProjectNotes.forEach((upn) => {
    const key = buildUnisonProjectNoteKey(upn.unisonProjectID, upn.noteID);
    byID[key] = formatUnisonProjectNote(upn);
    byUnisonProjectID[upn.unisonProjectID] = addToIDArray(
      byUnisonProjectID[upn.unisonProjectID],
      key
    );
  });
  return { byID, byUnisonProjectID };
}

export function buildUnisonProjectNoteKey(
  unisonProjectID: number,
  noteID: number
) {
  return `${unisonProjectID}.${noteID}`;
}

export const {
  setUnisonProjectNotes,
  addUnisonProjectNote,
  updateUnisonProjectNote,
} = unisonProjectNotesSlice.actions;

export default unisonProjectNotesSlice.reducer;
