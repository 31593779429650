import { mapToKey } from '@/utils/arrays';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DecoratedNote } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import {
  addUnisonProjectNote,
  setUnisonProjectNotes,
} from './unisonProjectNotesSlice';

type NotesState = {
  byID: Record<string, Omit<DecoratedNote, 'noteComments'>>;
};

const initialState = {
  byID: {},
} satisfies NotesState as NotesState;

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setNotes: (state, action: PayloadAction<DecoratedNote[]>) => {
      return getNextState(state, action.payload);
    },
    addNote: (state, action: PayloadAction<DecoratedNote>) => {
      return getNextState(state, [action.payload]);
    },
    updateNote: (state, action: PayloadAction<DecoratedNote>) => {
      return getNextState(state, [action.payload]);
    },
    deleteNote: (state, action: PayloadAction<{ noteID: number }>) => {
      delete state.byID[`${action.payload.noteID}`];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {} };
      })
      .addCase(setUnisonProjectNotes, (state, action) => {
        return getNextState(state, mapToKey(action.payload, 'note'));
      })
      .addCase(addUnisonProjectNote, (state, action) => {
        return getNextState(state, [action.payload.note]);
      })
      .addDefaultCase(() => {});
  },
});

function formatNote({ noteComments, ...note }: DecoratedNote) {
  return note;
}

function getNextState(state: NotesState, notes: DecoratedNote[]) {
  let byID = { ...state.byID };
  notes.forEach((note) => {
    const key = `${note.id}`;
    byID[key] = formatNote(note);
  });
  return { byID };
}

export const { setNotes, addNote, updateNote, deleteNote } = notesSlice.actions;

export default notesSlice.reducer;
