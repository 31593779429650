import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DecoratedNote, NoteComment } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { deleteNote, setNotes } from './notesSlice';
import {
  addUnisonProjectNote,
  setUnisonProjectNotes,
} from './unisonProjectNotesSlice';
import { addToIDArray } from '@/store/utils/stateFormatting';
import { filterOutValue } from '@/utils/arrays';

type NoteCommentsState = {
  byID: Record<string, NoteComment>;
  byNoteID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byNoteID: {},
} satisfies NoteCommentsState as NoteCommentsState;

export const noteCommentsSlice = createSlice({
  name: 'noteComments',
  initialState,
  reducers: {
    addNoteComment: (state, action: PayloadAction<NoteComment>) => {
      return getNextState(state, [action.payload]);
    },
    updateNoteComment: (state, action: PayloadAction<NoteComment>) => {
      return getNextState(state, [action.payload]);
    },
    deleteNoteComment: (
      state,
      action: PayloadAction<{ noteCommentID: number }>
    ) => {
      const key = `${action.payload.noteCommentID}`;
      if (!state.byID[key]) return state;
      const { noteID } = state.byID[key];
      if (state.byNoteID[noteID]) {
        state.byNoteID[noteID] = filterOutValue(state.byNoteID[noteID], key);
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byNoteID: {} };
      })
      .addCase(setNotes, (state, action) => {
        return getNextState(state, flattenNotes(action.payload));
      })
      .addCase(deleteNote, (state, action) => {
        const keys = state.byNoteID[action.payload.noteID] || [];
        keys.forEach((key) => {
          const comment = state.byID[key];
          if (!comment) return;
          state.byNoteID[comment.noteID] = filterOutValue(
            state.byNoteID[comment.noteID],
            key
          );
          delete state.byID[key];
        });
      })
      .addCase(setUnisonProjectNotes, (state, action) => {
        return getNextState(
          state,
          flattenNotes(action.payload.map(({ note }) => note))
        );
      })
      .addCase(addUnisonProjectNote, (state, action) => {
        return getNextState(state, flattenNotes([action.payload.note]));
      })
      .addDefaultCase(() => {});
  },
});

function flattenNotes(notes: DecoratedNote[]) {
  return notes.flatMap((note) => note.noteComments);
}

function getNextState(state: NoteCommentsState, noteComments: NoteComment[]) {
  let byID = { ...state.byID };
  let byNoteID = { ...state.byNoteID };
  noteComments.forEach((comment) => {
    const key = `${comment.id}`;
    byID[key] = comment;
    byNoteID[comment.noteID] = addToIDArray(byNoteID[comment.noteID], key);
  });
  return { byID, byNoteID };
}

export const { addNoteComment, updateNoteComment, deleteNoteComment } =
  noteCommentsSlice.actions;

export default noteCommentsSlice.reducer;
