'use client';
import '../index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { type PropsWithChildren, Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Slide, ToastContainer } from 'react-toastify';
import RollbarProvider from '@/providers/RollbarProvider';
import AppProviders from '@/providers/AppProviders';
import LoadingApp from '@/components/LoadingApp';
import { theme } from '@/utils/theme';
import { classes } from './utils';

export default function Layout({ children }: PropsWithChildren) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="witMetrics CRM" />
        <title>Unison | witMetrics</title>
        {/* FAVICON */}
        <link rel="icon" href="/favicon/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        {/* END FAVICON */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined"
          rel="stylesheet"
        />
      </head>
      <body>
        <RollbarProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<LoadingApp />}>
                <DndProvider
                  backend={MultiBackend}
                  options={getBackendOptions()}>
                  <AppProviders>
                    <div className={classes.app}>
                      <ToastContainer
                        hideProgressBar
                        closeOnClick
                        newestOnTop
                        pauseOnFocusLoss
                        pauseOnHover
                        rtl={false}
                        draggable={false}
                        theme="light"
                        position="bottom-right"
                        autoClose={3000}
                        transition={Slide}
                      />
                      {children}
                    </div>
                  </AppProviders>
                </DndProvider>
              </Suspense>
            </ThemeProvider>
          </StyledEngineProvider>
        </RollbarProvider>
      </body>
    </html>
  );
}
