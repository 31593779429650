import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addLead, setLeads, updateLead } from './leadsSlice';
import { setActiveAccount } from './activeAccountSlice';
import {
  DecoratedLead,
  DecoratedUserProperty,
  UndecoratedUserProperty,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';
import type { Identifier } from '@/store/utils/buildState';
import { filterOutValue } from '@/utils/arrays';

type UserPropertiesState = {
  byID: Record<string, UndecoratedUserProperty>;
  byUserID: Record<string, string[]>;
  byPropertyID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUserID: {},
  byPropertyID: {},
} satisfies UserPropertiesState as UserPropertiesState;

export const userPropertiesSlice = createSlice({
  name: 'userProperties',
  initialState,
  reducers: {
    setUserProperties: (
      state,
      action: PayloadAction<DecoratedUserProperty[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addUserProperty: (state, action: PayloadAction<DecoratedUserProperty>) => {
      return getNextState(state, [action.payload]);
    },
    updateUserProperty: (
      state,
      action: PayloadAction<DecoratedUserProperty>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteUserProperty: (state, action: PayloadAction<{ userID: number }>) => {
      const keys = state.byUserID[action.payload.userID] || [];
      keys.forEach((key) => {
        const userProperty = state.byID[key];
        if (!userProperty) return;
        state.byUserID[userProperty.userID] = filterOutValue(
          state.byUserID[userProperty.userID],
          key
        );
        state.byPropertyID[userProperty.propertyID] = filterOutValue(
          state.byPropertyID[userProperty.propertyID],
          key
        );
        delete state.byID[key];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byUserID: {}, byPropertyID: {} };
      })
      .addCase(setLeads, (state, action) => {
        return getNextState(state, flattenUserProperties(action.payload));
      })
      .addCase(addLead, (state, action) => {
        return getNextState(state, action.payload.userProperties);
      })
      .addCase(updateLead, (state, action) => {
        return getNextState(state, action.payload.userProperties);
      })
      .addDefaultCase(() => {});
  },
});

function flattenUserProperties(users: DecoratedLead[]) {
  return users.reduce(
    (arr, user) => [...arr, ...user.userProperties],
    [] as DecoratedUserProperty[]
  );
}

function formatUserProperty({
  property,
  ...userProperty
}: DecoratedUserProperty) {
  return userProperty;
}

function getNextState(
  state: UserPropertiesState,
  userProperties: DecoratedUserProperty[]
) {
  let byID = { ...state.byID };
  let byUserID = { ...state.byUserID };
  let byPropertyID = { ...state.byPropertyID };
  userProperties.forEach((up) => {
    const key = buildUserPropertyKey(up.userID, up.propertyID);
    byID[key] = formatUserProperty(up);
    byUserID[up.userID] = addToIDArray(byUserID[up.userID], key);
    byPropertyID[up.propertyID] = addToIDArray(byUserID[up.propertyID], key);
  });
  return { byID, byUserID, byPropertyID };
}

export function buildUserPropertyKey(
  userID: Identifier,
  propertyID: Identifier
) {
  return `${userID}.${propertyID}`;
}

export default userPropertiesSlice.reducer;
