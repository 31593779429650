import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  DecoratedInteraction,
  UndecoratedInteraction,
} from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { addToIDArray } from '@/store/utils/stateFormatting';

type InteractionsState = {
  byID: Record<string, UndecoratedInteraction>;
  byUserID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUserID: {},
} satisfies InteractionsState as InteractionsState;

export const interactionsSlice = createSlice({
  name: 'interactions',
  initialState,
  reducers: {
    setInteractions: (state, action: PayloadAction<DecoratedInteraction[]>) => {
      return getNextState(state, action.payload);
    },
    addInteraction: (state, action: PayloadAction<DecoratedInteraction>) => {
      return getNextState(state, [action.payload]);
    },
    updateInteraction: (state, action: PayloadAction<DecoratedInteraction>) => {
      return getNextState(state, [action.payload]);
    },
    deleteInteraction: (
      state,
      action: PayloadAction<{ interactionID: number }>
    ) => {
      delete state.byID[`${action.payload.interactionID}`];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byUserID: {} };
      })
      .addDefaultCase(() => {});
  },
});

function formatInteraction({
  interactionComments,
  ...interaction
}: DecoratedInteraction) {
  return interaction;
}

function getNextState(
  state: InteractionsState,
  interactions: DecoratedInteraction[]
) {
  let byID = { ...state.byID };
  let byUserID = { ...state.byUserID };
  interactions.forEach((interaction) => {
    const key = `${interaction.id}`;
    byID[key] = formatInteraction(interaction);
    byUserID[interaction.userID] = addToIDArray(
      byUserID[interaction.userID],
      key
    );
  });
  return { byID, byUserID };
}

export const {
  setInteractions,
  addInteraction,
  updateInteraction,
  deleteInteraction,
} = interactionsSlice.actions;

export default interactionsSlice.reducer;
