import { createSlice } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import {
  addConversationMessage,
  setConversationMessages,
} from './conversationMessagesSlice';
import {
  DecoratedConversationMessageFile,
  UndecoratedConversationMessageFile,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type ConversationMessageFilesState = {
  byID: Record<string, UndecoratedConversationMessageFile>;
  byMessageID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byMessageID: {},
} satisfies ConversationMessageFilesState as ConversationMessageFilesState;

export const conversationMessageFilesSlice = createSlice({
  name: 'conversationMessageFiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byMessageID: {} };
      })
      .addCase(setConversationMessages, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap((m) => m.files)
        );
      })
      .addCase(addConversationMessage, (state, action) => {
        return getNextState(state, action.payload.files);
      })
      .addDefaultCase(() => {});
  },
});

function formatConversationMessageFile({
  file,
  ...conversationMessageFile
}: DecoratedConversationMessageFile) {
  return conversationMessageFile;
}

function getNextState(
  state: ConversationMessageFilesState,
  conversationMessageFiles: DecoratedConversationMessageFile[]
) {
  let byID = { ...state.byID };
  let byMessageID = { ...state.byMessageID };
  conversationMessageFiles.forEach((cmf) => {
    const key = buildConversationMessageFileKey(
      cmf.conversationMessageID,
      cmf.fileID
    );
    byID[key] = formatConversationMessageFile(cmf);
    byMessageID[cmf.conversationMessageID] = addToIDArray(
      byMessageID[cmf.conversationMessageID],
      key
    );
  });
  return { byID, byMessageID };
}

export function buildConversationMessageFileKey(
  messageID: number,
  fileID: number
) {
  return `${messageID}.${fileID}`;
}

export const {} = conversationMessageFilesSlice.actions;

export default conversationMessageFilesSlice.reducer;
