import { createSlice } from '@reduxjs/toolkit';
import { DecoratedPracticeUser, UndecoratedUser } from '@witmetrics/api-client';
import { setActiveAccount } from '@/store/slices/activeAccountSlice';
import { setPracticeUsers } from '@/store/slices/userPracticesSlice';

type UsersState = {
  byID: Record<string, UndecoratedUser>;
};

const initialState = {
  byID: {},
} satisfies UsersState as UsersState;

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {} };
      })
      .addCase(setPracticeUsers, (state, action) => {
        return getNextState(state, action.payload.map(formatUserPractice));
      })
      .addDefaultCase(() => {});
  },
});

function formatUserPractice({ user }: DecoratedPracticeUser) {
  return user;
}

function getNextState(state: UsersState, users: UndecoratedUser[]) {
  let byID = { ...state.byID };
  users.forEach((user) => {
    byID[`${user.id}`] = user;
  });
  return { byID };
}

export default usersSlice.reducer;
