import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  DecoratedInteraction,
  InteractionComment,
} from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { deleteInteraction, setInteractions } from './interactionsSlice';
import { addToIDArray } from '@/store/utils/stateFormatting';
import { filterOutValue } from '@/utils/arrays';

type InteractionCommentsState = {
  byID: Record<string, InteractionComment>;
  byInteractionID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byInteractionID: {},
} satisfies InteractionCommentsState as InteractionCommentsState;

export const interactionCommentsSlice = createSlice({
  name: 'interactionComments',
  initialState,
  reducers: {
    addInteractionComment: (
      state,
      action: PayloadAction<InteractionComment>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateInteractionComment: (
      state,
      action: PayloadAction<InteractionComment>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteInteractionComment: (
      state,
      action: PayloadAction<{ interactionCommentID: number }>
    ) => {
      const key = `${action.payload.interactionCommentID}`;
      if (!state.byID[key]) return state;
      const { interactionID } = state.byID[key];
      if (state.byInteractionID[interactionID]) {
        state.byInteractionID[interactionID] = filterOutValue(
          state.byInteractionID[interactionID],
          key
        );
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byInteractionID: {} };
      })
      .addCase(setInteractions, (state, action) => {
        return getNextState(state, flattenInteractions(action.payload));
      })
      .addCase(deleteInteraction, (state, action) => {
        const keys = state.byInteractionID[action.payload.interactionID] || [];
        keys.forEach((key) => {
          const comment = state.byID[key];
          if (!comment) return;
          state.byInteractionID[comment.interactionID] = filterOutValue(
            state.byInteractionID[comment.interactionID],
            key
          );
          delete state.byID[key];
        });
      })
      .addDefaultCase(() => {});
  },
});

function flattenInteractions(interactions: DecoratedInteraction[]) {
  return interactions.reduce(
    (a, b) => [...a, ...b.interactionComments],
    [] as InteractionComment[]
  );
}

function getNextState(
  state: InteractionCommentsState,
  interactionComments: InteractionComment[]
) {
  let byID = { ...state.byID };
  let byInteractionID = { ...state.byInteractionID };
  interactionComments.forEach((interactionComment) => {
    const key = `${interactionComment.id}`;
    byID[key] = interactionComment;
    byInteractionID[interactionComment.interactionID] = addToIDArray(
      byInteractionID[interactionComment.interactionID],
      key
    );
  });
  return { byID, byInteractionID };
}

export const {
  addInteractionComment,
  updateInteractionComment,
  deleteInteractionComment,
} = interactionCommentsSlice.actions;

export default interactionCommentsSlice.reducer;
