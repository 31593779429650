import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setCurrentUser } from './currentUserSlice';
import { PREFERENCE_IDS } from '@/constants/preferences';
import {
  DecoratedPracticePreference,
  FetchCurrentUserResponse,
  FetchPracticePreferencesResponse,
  UndecoratedPracticePreference,
} from '@witmetrics/api-client';
import { addToIDArray, generateID } from '@/store/utils/stateFormatting';

const keyStructure = 'practiceID.preferenceID';

interface PracticePreference
  extends Omit<UndecoratedPracticePreference, 'value'> {
  value: string | number | null | undefined;
}

type PracticePreferencesState = {
  byID: Record<string, PracticePreference>;
  byPracticeID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byPracticeID: {},
} satisfies PracticePreferencesState as PracticePreferencesState;

export const practicePreferencesSlice = createSlice({
  name: 'practicePreferences',
  initialState,
  reducers: {
    setPracticePreferences: (
      state,
      action: PayloadAction<FetchPracticePreferencesResponse>
    ) => {
      let byID = { ...state.byID };
      let byPracticeID = { ...state.byPracticeID };
      action.payload.forEach((practicePreference) => {
        const key = generateID(practicePreference, keyStructure);
        byID[key] = formatPracticePreference(practicePreference);
        byPracticeID[practicePreference.practiceID] = addToIDArray(
          byPracticeID[practicePreference.practiceID],
          key
        );
      });
      state.byID = byID;
      state.byPracticeID = byPracticeID;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser, (state, action) => {
        const practicePreferences = flattenUserPractices(
          action.payload.userPractices
        );
        let byID = { ...state.byID };
        let byPracticeID = { ...state.byPracticeID };
        practicePreferences.forEach((practicePreference) => {
          const key = buildPracticePreferenceKey(
            practicePreference.practiceID,
            practicePreference.preferenceID
          );
          byID[key] = formatPracticePreference(practicePreference);
          byPracticeID[practicePreference.practiceID] = addToIDArray(
            byPracticeID[practicePreference.practiceID],
            key
          );
          state.byID = byID;
          state.byPracticeID = byPracticeID;
        });
      })
      .addDefaultCase(() => {});
  },
});

function flattenUserPractices(
  userPractices: FetchCurrentUserResponse['userPractices']
) {
  return userPractices.reduce(
    (a: DecoratedPracticePreference[], up) => [
      ...a,
      ...up.practice.preferences,
    ],
    []
  );
}

function formatPracticePreference({
  preference,
  ...practicePreference
}: DecoratedPracticePreference) {
  if (practicePreference.preferenceID === PREFERENCE_IDS.WEEK_STARTS_ON) {
    return { ...practicePreference, value: parseInt(practicePreference.value) };
  } else {
    return practicePreference;
  }
}

export function buildPracticePreferenceKey(
  practiceID: number,
  preferenceID: number
) {
  return `${practiceID}.${preferenceID}`;
}

export const { setPracticePreferences } = practicePreferencesSlice.actions;

export default practicePreferencesSlice.reducer;
