import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  DecoratedBooking,
  DecoratedPractice,
  DecoratedProperty,
} from '@witmetrics/api-client';
import { filterOutValue } from '@/utils/arrays';
import { addPractice } from './practicesSlice';
import { setCurrentUser } from './currentUserSlice';
import { addToIDArray } from '@/store/utils/stateFormatting';
import { ParsedProperty } from '@/types/properties';

type PropertiesState = {
  byID: Record<string, ParsedProperty>;
  byPracticeID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byPracticeID: {},
} satisfies PropertiesState as PropertiesState;

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setProperties: (state, action: PayloadAction<DecoratedProperty[]>) => {
      return getNextState(state, action.payload);
    },
    addProperty: (state, action: PayloadAction<DecoratedProperty>) => {
      return getNextState(state, [action.payload]);
    },
    updateProperty: (state, action: PayloadAction<DecoratedProperty>) => {
      return getNextState(state, [action.payload]);
    },
    deleteProperty: (state, action: PayloadAction<{ propertyID: number }>) => {
      const key = `${action.payload.propertyID}`;
      const property = state.byID[key];
      if (!property) return state;
      const { practiceID } = property;
      if (state.byPracticeID[practiceID]) {
        state.byPracticeID[practiceID] = filterOutValue(
          state.byPracticeID[practiceID],
          key
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser, (state, action) => {
        return getNextState(
          state,
          flattenPractices(
            action.payload.userPractices.map((up) => up.practice)
          )
        );
      })
      .addCase(addPractice, (state, action) => {
        return getNextState(state, action.payload.properties);
      })
      .addDefaultCase(() => {});
  },
});

function flattenPractices(practices: DecoratedPractice[]) {
  return practices.reduce(
    (a, b) => [...a, ...b.properties],
    [] as DecoratedProperty[]
  );
}

function flattenBookings(bookings: DecoratedBooking[]) {
  return bookings.reduce(
    (a, b) => [...a, ...b.user.userProperties.map((up) => up.property)],
    [] as DecoratedProperty[]
  );
}

function formatProperty(property: DecoratedProperty): ParsedProperty {
  return property.acceptedValues
    ? { ...property, acceptedValues: JSON.parse(property.acceptedValues) }
    : { ...property, acceptedValues: null };
}

function getNextState(state: PropertiesState, properties: DecoratedProperty[]) {
  let byID = { ...state.byID };
  let byPracticeID = { ...state.byPracticeID };
  properties.forEach((p) => {
    const key = `${p.id}`;
    byID[key] = formatProperty(p);
    byPracticeID[p.practiceID] = addToIDArray(byPracticeID[p.practiceID], key);
  });
  return { byID, byPracticeID };
}

export const { setProperties, addProperty, updateProperty, deleteProperty } =
  propertiesSlice.actions;

export default propertiesSlice.reducer;
